import request from '../../request'

export const QueryTeacherIndexData = () => {
    return request.get(`eduIndex/queryTeacherIndexData`).then(res => res.data)
}

// 教务公告
export const SelectEduCourseNoticeByPage = (params) => {
    return request.get(`teacherNotice/selectEduCourseNoticeByPage`, {
        params
    }).then(res => res.data)
}


// 教务公告 - 查看
export const SelectEduNoticeById = (params) => {
    return request.get(`teacherNotice/selectEduNoticeById`, {
        params
    }).then(res => res.data)
}


// 下载错误信息
export const DownloadErrMessageEduTeachPlan = () => {
    return request.get(`eduCourseWorkSetTea/downloadErrMessageEduTeachPlan`).then(res => res.data)
}