<style scoped lang="less">
.t_result_manage {
  box-shadow: @shadow;
  background: #fff;

  .stu-module-header {
    border-bottom: 1px solid @borderBottomColor;
    padding: 20px;
  }

  .content {
    padding: 0 20px 20px;
  }
}
</style>
<template>
  <div class="t_result_manage">
    <div class="stu-module-header">
      <div class="stu-module-title">成绩管理</div>
      <div>
        <el-button type="primary" @click="downloadResult">导出成绩</el-button>
        <el-button type="primary" @click="importResult">导入成绩</el-button>
      </div>
    </div>
    <el-tabs v-model="active" @tab-click="handleClick">
      <el-tab-pane label="未通过列表" name="one"></el-tab-pane>
      <el-tab-pane label="已通过列表" name="two"></el-tab-pane>
    </el-tabs>
    <div class="content">
      <el-form :model="form" size="medium" inline ref="result_manage_ref" >
        <el-form-item label="姓名/学号" prop="name">
          <el-input v-model="bo.info" clearable></el-input>
        </el-form-item>
        <el-form-item label="函授站" prop="siteName">
          <el-select
              v-model="bo.siteId"
              clearable
              placeholder="请选择"
          >
            <el-option label="请选择" value=""></el-option>
            <el-option
                v-for="item in siteIdData"
                :key="item.id"
                :label="item.siteName"
                :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="query" type="primary">查询</el-button>
<!--          <el-button @click="reset">重置</el-button>-->
        </el-form-item>
      </el-form>
      <el-table :data="tableData" :height="height" stripe>
        <el-table-column label="序号" width="65" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + (current - 1) * pageSize + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
            v-for="(col, i) in column"
            :key="i"
            :label="col.label"
            :prop="col.prop"
            :min-width="col.width"
            :sortable="col.sortable"
            align="center"
            show-overflow-tooltip
        ></el-table-column>
      </el-table>
      <el-pagination
          class="pagination"
          background
          @current-change="handleCurrentChange"
          :current-page="current"
          :page-size="pageSize"
          layout="total, prev, pager, next, jumper"
          :total="tableData.length"
      >
      </el-pagination>
    </div>
    <ImportResult ref="import_result_ref" @changeFile="changeFile($event)"/>
  </div>
</template>

<script>
import ImportResult from "./module/ImportResult";
import SiteSelectAll from "../../../../components/public/site-select-all";

export default {
  name: "t_result_manage",
  components: {SiteSelectAll, ImportResult},
  data() {
    return {
      height: window.innerHeight - 450,
      active: "one",
      form: {
        name: null,
        siteName: null,
      },
      siteNameOptions: [],
      gradeOptions: [],

      tableData: [],
      column: [
        {label: "姓名", prop: "name"},
        {label: "学号", prop: "studentNo"},
        {label: "函授站", prop: "siteName"},
        {label: "离线学习成绩", prop: "offlineScore", width: 120, sortable: true},
        {label: "交互成绩", prop: "mutualScore", width: 120, sortable: true},
        {label: "在线作业成绩", prop: "workScore"},
        {label: "考试成绩", prop: "examScore", width: 120, sortable: true},
        {label: "总评成绩", prop: "overallScore", sortable: true},
      ],
      total: 0,
      pageSize: 10,
      current: 1,
      index: 0,
      siteIdData: [],
      bo: {
        siteId: '',
        info: '',
        passFlag: 0,
      },
    };
  },
  computed: {
    course() {
      return this.$store.getters.getTCourse;
    }
  },
  mounted() {
    this.query();
    this.queryAllSiteByTeacher();
  },
  methods: {
    download() {

    },
    handleClick(tab) {
      this.index = tab.index;
      this.query(tab.index);
    },
    changeFile(row){
      let formData = new FormData();
      formData.append("file",row.raw);
      formData.append("courseId",this.course.id);
      this.$axios_supermall.post("/hnjxjy-core/eduCourseWorkSetTea/eduTeachPlanImportTemplate",formData).then(resp =>{
        if (resp.data.code === 2000){
          this.$message.success(resp.data.data);
        }else{
          //this.$message.error(resp.data.data);
        }
      }).catch((err) => {
        this.$message.error("导入失败");
      })
    },
    query() {
      this.bo.passFlag = this.index;
      this.openLoadingView();
      this.$axios_supermallData.get("/hnjxjy-core/eduCourseWorkSetTea/queryAchievementByCourseId?courseId=" + this.course.id + "&siteId=" + this.bo.siteId + "&info=" + this.bo.info + "&passFlag=" + this.bo.passFlag + "&page=" + this.current + "&pageSize=" + this.pageSize).then(resp => {
        if (resp.data.code == 2000) {
          this.tableData = resp.data.data.list;
          this.total = resp.data.data.total;
        }
        this.loadingView.close();
      }).catch(()=>{
        this.loadingView.close();
      })
    },
    init() {
    },
    downloadResult() {
      this.$axios_supermallDwon.get("/hnjxjy-core/eduCourseWorkSetTea/exportAchievementExcel?courseId="+this.course.id+"&passFlag="+this.index).then(resp => {
        let blob = new Blob([resp.data], {
          type: "application/vnd.ms-excel",
        });
        let url = window.URL.createObjectURL(blob);
        window.location.href = url;
      });
    },
    importResult() {
      this.$refs["import_result_ref"].dialog = true;
    },
    handleCurrentChange(page) {
      if (page) {
        this.current = page;
        this.init();
      }
    },
    reset() {
      this.$refs["result_manage_ref"].resetFields();
    },
    queryAllSiteByTeacher(){
      this.$axios_supermallData({
        url:"/hnjxjy-core/eduSite/queryAllSiteByTeacher",
        method: 'get',
        params: {
          isAll: 1
        }
      })
      .then(resp => {
        if (resp.data.code === 2000){
          this.siteIdData = resp.data.data;
        }
      })
      .catch(err => {
      })
    },
  },
};
</script>
