import axios from 'axios'
import router from '../router'
import explorer from './explorer'
import {
    Message
} from 'element-ui'
import {
    ROOT
} from '../tools/const.js'
import {
    BASE_URL,
    BASE_URL_2
} from './domain'
const request = axios.create({
    baseURL: BASE_URL,
    timeout: 5 * 60 * 1000,
})
request.defaults.headers.post['Content-Type'] = 'application/json'

request.interceptors.request.use(config => {
    // 浏览器为ie内核时，为每个请求增加时间戳，解决ie浏览器下的缓存问题
    if (explorer === 'ie') {
        config.params = {
            _t: Date.parse(new Date()) / 1000,
            ...config.params
        }
    }
    let url = config.url;
    if (url.indexOf('hnjxjy-admin') === -1) {
        config.url = 'hnjxjy-core/' + config.url;
    }
    const auth = localStorage.loginStrage ? JSON.parse(localStorage.loginStrage) : null;
    if (auth) {
        config.headers['Authorization'] = auth['x-token']
        config.headers['x-token'] = auth['x-token']
        config.headers['x-perm'] = auth['x-perm']
    }
    let urls = url.replace('hnjxjy-admin/', '');
    const routePath = router.currentRoute.path.includes('/student/');
    const root = ROOT[routePath ? 'S' : 'T'];
    for (const key in root) {
        if (urls.includes(key)) config.headers['x-perm'] = root[key];
    }
    return config
})
request.interceptors.response.use(res => {
    const code = res.data.code
    if (code === 2000 || !code) return res
    if ( code === 10005 || code === 10006 || code === 10008) {
        localStorage.clear();
        router.push('/Online/index')
    }
    Message.error(res.data.message);
    const err = new Error()
    err.code = code
    err.message = res.data.message
    err.data = res.data.error
    return Promise.reject(err)
}, err => {
    const {
        response
    } = err;
    if (response) {
        // 请求已发出，但是不在2xx的范围
        handleError(response.status,response)
        return Promise.resolve(response);
    }
    return Promise.reject(err)
})

const handleError = (status,response) => {
    switch (status) {
        case 400:
            Message.error('参数错误');
            break;
        case 403:
            Message.error('拒绝访问');
            break;
        case 404:
            Message.error('请求错误，未找到该资源');
            break;
        case 408:
            Message.error('请求超时，请稍后重试');
            break;
        case 500:
            if(response.data.path == "/eduGraduateDegreeSet/queryStudentEnglish"){
                Message.error('不在申报时间内');
                break;
            }
            Message.error('服务器异常');
            break;
        case 501:
            Message.error('网络未实现');
            break;
        case 502:
            Message.error('网络错误，请稍后重试');
            break;
        case 503:
            Message.error('服务不可用');
            break;
        case 504:
            Message.error('您的网络不给力，请稍后重试');
            break;
        case 505:
            Message.error('http版本不支持该请求');
            break;
        default:
            Message.error('连接错误');
            break;
    }
    // 如果状态码为401 或者403等等 则跳转到登录页面
  const error_list = [403, 401,501,502,503]
  if (error_list.includes(status)) {
    // 跳转到登录页面 ,清空浏览器token信息
    router.push('/Online/index')
  } else {
    return Promise.reject(error)
  }
}
export default request