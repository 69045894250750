let explorer = navigator.userAgent;

// IE 11中userAgent已经不包含'msie'所以用'msie'不能判断IE 11
// IE 11的userAgent版本为Trident x.x
// 判断IE 11 用正则判断userAgent中有没有带Trident字样的字符串即可
let u = explorer.toLocaleLowerCase()
let ie = /(trident)\/([\d.]+)/;
let b = u.match(ie);

let result = '';

if (explorer.indexOf("MSIE") >= 0 || b) result = 'ie'

else if (explorer.indexOf("Firefox") >= 0) result = 'Firefox'

else if (explorer.indexOf("Chrome") >= 0) result = 'Chrome'

else if (explorer.indexOf("Opera") >= 0) result = 'Opera'

else if (explorer.indexOf("Safari") >= 0) result = 'Safari'

else if (explorer.indexOf("Netscape") >= 0) result = 'Netscape'

export default result
