<style scoped lang="less">
.result_manage_import_dialog {
  .upload-demo {
    text-align: center;
    margin: 20px 0;
  }
}
</style>
<template>
  <el-dialog
    :visible.sync="dialog"
    title="上传文件"
    width="40%"
    custom-class="result_manage_import_dialog"
  >
    <el-button @click="download" type="primary">下载模版</el-button>
    <span style="color: #ff0000">
      提示：导入前请先下载模板，根据模版整理好数据后上传导入.
    </span>
    <el-upload
        class="upload-demo"
        drag
        action="#"
        :on-change="handleChange"
        :auto-upload="false"
        accept=".xls,.xlsx"
    >
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      <div class="el-upload__tip" slot="tip">
        只能上传excel文件，且不超过50M
      </div>
    </el-upload>
    <div style="text-align: center">
      <el-button @click="errorInfo" type="primary">下载错误信息</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { DownloadErrMessageEduTeachPlan } from "@/libs/api/teacher/user";
export default {
  name: "result_manage_import_dialog",
  data() {
    return {
      dialog: false,
      file: null,
    };
  },
  methods: {
    beforeUpload(file) {},
    handleChange(file) {
      this.$emit("changeFile", file);
    },
    download() {
      this.$axios_supermallData({
        method: "get",
        url: "/hnjxjy-core/eduCourseWorkSetTea/downloadTemplate",
        responseType: "blob",
      }).then((resp) => {
        let blob = new Blob([resp.data], {
          type: "application/vnd.ms-excel",
        });
        let downloadElement = document.createElement('a');
        let href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = '成绩导入模板.xlsx';
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      });
    },
    errorInfo() {
      this.$axios_supermallDwon({
        method: "get",
        url: "/hnjxjy-core/eduCourseWorkSetTea/downloadErrMessageEduTeachPlan",
        responseType: "blob",
      }).then((resp) => {
        let blob = new Blob([resp.data], {
          type: "application/vnd.ms-excel",
        });
        let url = window.URL.createObjectURL(blob);
        window.location.href = url;
      });
    },
  },
};
</script>
